import { render, staticRenderFns } from "./trateralipayAdd.vue?vue&type=template&id=6785755c&scoped=true&"
import script from "./trateralipayAdd.vue?vue&type=script&lang=js&"
export * from "./trateralipayAdd.vue?vue&type=script&lang=js&"
import style0 from "./trateralipayAdd.vue?vue&type=style&index=0&id=6785755c&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6785755c",
  null
  
)

export default component.exports